import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import TermsPage from './TermsPage/TermsPage'

import { graphql } from 'gatsby'

import 'assets/scss/material-kit-react.scss?v=1.8.0'

const Terms = ({ data }) => {
  return (
    <Layout>
      <SEO title={'Terms & Conditions'} description={'The legal stuff...'} />
      <TermsPage data={data} />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulSiteInfo {
      terms {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Terms
